<template>
  <div class="page">
    很抱歉，您无权限访问
  </div>
</template>

<script>
export default {

};
</script>

<style>
.page {
  width: 100%;
  height: 100%;
  background: #0094ff;
  color: white;
  font-size: 40px;
  text-align: center;
  line-height: 200px;
}
</style>
